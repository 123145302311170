import SelectorEngine from '../dom/selector-engine';
import { isVisible } from './index';

const DEFAULT_OPTIONS = {
  position: 'top',
  container: null,
  refresh: 1000,
  filter: (el) => {
    return el;
  },
};

class Stack {
  constructor(element, selector, options) {
    this._element = element;
    this._selector = selector;
    this._options = { ...DEFAULT_OPTIONS, ...options };

    this.previousElements = [];
    this._offset = null;
  }

  get stackableElements() {
    return SelectorEngine.find(this._selector)
      .filter((el, i) => this._options.filter(el, i))
      .map((el) => ({ el, rect: el.getBoundingClientRect() }))
      .filter(({ el, rect }) => {
        const basicCondition = el !== this._element && isVisible(el);
        if (this._offset === null) {
          return basicCondition;
        }

        return basicCondition && this._getBoundryOffset(rect) < this._offset;
      })
      .sort((a, b) => {
        return this._getBoundryOffset(b.rect) - this._getBoundryOffset(a.rect);
      });
  }

  get nextElements() {
    return SelectorEngine.find(this._selector)
      .filter((el, i) => this._options.filter(el, i))
      .filter((el) => {
        return this._getBoundryOffset(el.getBoundingClientRect()) > this._offset;
      });
  }

  _getBoundryOffset(rect) {
    const { position } = this._options;
    if (position === 'top') {
      return rect.top;
    }
    return window.innerHeight - rect.bottom;
  }

  calculateOffset() {
    this.previousElements = this.stackableElements;
    const [previousElement] = this.previousElements;

    if (!previousElement) {
      this._offset = 0;
    } else {
      this._offset = this._getBoundryOffset(previousElement.rect) + previousElement.rect.height;
    }

    return this._offset;
  }
}

export default Stack;
